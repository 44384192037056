import React from 'react'
import {
  Banner,
  Footer,
  Head,
  BenefitsConsortium,
  Coverages,
  LinkInsurances,
  TalkUs,
  Feedbacks,
} from '~/components'
import { useApi } from '~/api'

import bannerConsorcio from '~/assets/img/banner-consorcio.jpg'
import bannerConsorcioMobile from '~/assets/img/banner-mobile-consorcio.jpg'
import { PageProps } from 'gatsby'

interface Feedback {
  image: string
  name: string
  office: string
  description: string
}

const Consorcio = ({ location, pageContext }: PageProps) => {
  const context = useApi(pageContext, 'page-home')
  const { testimonials } = context

  const linkInsurancesData = {
    ensureTitle: 'Faça seu consórcio e realize os seus sonhos com facilidade.',
    ensureCta: 'Saiba mais',
  }

  const talkusData = {
    formTitle: 'Precisando saber mais sobre o Consórcio da Dimas Seguros?',
    formContent:
      'Utilize o formulário e envie uma mensagem para nossos consultores. ',
  }

  const coveragesData = {
    safetyTitle: 'Consórcios disponíveis',
    safetyResume:
      'Conte com a Dimas Seguros para planejar a compra do seu imóvel ou veículo dos sonhos',
    safetyContent: [
      {
        name: 'Consórcio de Imóvel',
        description:
          'Para quem quer comprar casa, apartamento ou terreno; ou mesmo começar uma construção ou reforma.',
      },
      {
        name: 'Consórcio de Carro',
        description: 'Conquiste seu carro novo ou seminovo!',
      },
    ],
  }

  return (
    <>
      <Head />
      <main>
        <Banner
          widthConsortium
          mobile={bannerConsorcioMobile}
          img={bannerConsorcio}
          title="Consórcio"
          content="Dimas Seguros: tornando o seu sonho uma realidade"
        />
        <BenefitsConsortium />
        <Coverages
          widthConsortium
          data={coveragesData}
          pathname={'consorcio'}
        />
        <LinkInsurances consorcio data={linkInsurancesData} />
        <TalkUs data={talkusData} />
        <Feedbacks data={testimonials} />
      </main>
      <Footer />
    </>
  )
}

export default Consorcio
